import React, { Component } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudy.scss';

import imgQuote from "../images/quotation_mark.webp";

const caseStudyItems = [
    {
        caseStudyTitle:'ABM for banking and financial services',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2025/03/finastra-cs-bg.webp',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2025/03/finastra-microsoft-logo-lockup-white.png',
        caseStudyUrl:'/case-studies/finastra-microsoft/',
        caseStudyCategories: [
            "advertising",
            "research"
        ],
    },
    {
        caseStudyTitle:'Integrated PR for fleet management',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2024/09/fleet-advantage-case-study-thumbnail.webp',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2024/09/fleet-advantage-logo-white.png',
        caseStudyUrl:'/case-studies/fleet-advantage/',
        caseStudyCategories: [
            "public-relations",
            "research"
        ],
    },
    {
        caseStudyTitle:'ABM and thought leadership for virtual healthcare',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2024/05/teledoc-case-study-thumbnail.webp',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2024/05/teladoc-health-microsoft-logo-lockup.webp',
        caseStudyUrl:'/case-studies/teladoc-health-microsoft/',
        caseStudyCategories: [
            "advertising",
            "research"
        ],
    },
    {
        caseStudyTitle:'Sales enablement for Microsoft partners',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2024/02/genzeon-thumb.webp',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2024/02/genzeon-logo-white.webp',
        caseStudyUrl:'/case-studies/genzeon/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'B2C advertising for consumer electronics',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2023/03/iridium-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2023/03/iridium-logo-white.png',
        caseStudyUrl:'/case-studies/iridium/',
        caseStudyCategories: [
            "advertising",
        ],
    },
    {
        caseStudyTitle:'Telling the “Ultimate” Story',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/10/audl-cs-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/10/audl-cs-thumb-logo-1.png',
        caseStudyUrl:'/case-studies/audl-pr/',
        caseStudyCategories: [
            "public-relations",
            "research",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'Customer experience demand generation',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/09/microsoftnuance-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/09/microsoftnuance-logo.png',
        caseStudyUrl:'/case-studies/microsoft-nuance/',
        caseStudyCategories: [
            "advertising",
            "research"
        ],
    },
    {
        caseStudyTitle:'Integrated marketing, PR, and sales campaigns',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/09/cs-kddi-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/09/kddi-white.svg',
        caseStudyUrl:'/case-studies/kddiamerica/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Telling the thought leadership story of DaaS benefits',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/08/citrix-chrome-pr-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/08/cccs-citrix-google-logos.svg',
        caseStudyUrl:'/case-studies/citrix-google-earned-media/',
        caseStudyCategories: [
            "advertising",
            "public-relations",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'Up-leveling visibility for Microsoft partners\' service offerings',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/08/thumb-microsoft-business-applications.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/08/logo-microsoft-white.png',
        caseStudyUrl:'/case-studies/microsoft-business-applications/',
        caseStudyCategories: [
            "advertising"
        ],
    },
    {
        caseStudyTitle:'The formula for vertical marketing',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2022/08/cccs-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2022/08/cccs-citrix-google-logos.svg',
        caseStudyUrl:'/case-studies/citrix-chromeos-vertical-marketing/',
        caseStudyCategories: [
            "advertising",
            "public-relations"
        ],
    },
    {
        caseStudyTitle:'Showcasing history while leaning into the future',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2021/12/brhs-cs-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2021/12/brhs-logo-vertical-w.png',
        caseStudyUrl:'/case-studies/boca-raton-historical-society/',
        caseStudyCategories: [
            "advertising",
            "public-relations",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'Laser-focused lead generation',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2021/11/capgemini-bg.png',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2021/11/capgemini-logo-1.png',
        caseStudyUrl:'/case-studies/capgemini/',
        caseStudyCategories: [
            "advertising",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'Amplifying awareness to capture targeted leads',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2021/10/dxc-thumb.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2021/10/icon-dxc.svg',
        caseStudyUrl:'/case-studies/dxc/',
        caseStudyCategories: [
            "public-relations",
            "interactive",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'A focus on customer experience',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2021/02/portfolio-inset-lifesync.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2021/02/lifesync-logo-white.svg',
        caseStudyUrl:'/case-studies/lifesync/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Alliance Marketing Done Right',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2021/02/portfolio-inset-cit-msft.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2021/02/alliance-whtAsset.svg',
        caseStudyUrl:'/case-studies/citrix-and-microsoft/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Creating distinction in a crowd',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-anywhere.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/anywhereapp-logo-white.svg',
        caseStudyUrl:'/case-studies/anywhereapp/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Professional branding  for amateur athletics',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-bocajets.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/boca-jets-logo-white.svg',
        caseStudyUrl:'/case-studies/boca-jets-lacrosse/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Recruiting and motivating',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-citrix.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/03/icon-citrix.svg',
        caseStudyUrl:'/case-studies/citrix/',
        caseStudyCategories: [
            "advertising",
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Communicating insight and innovation',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-equifax.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/equifax-logo-white.svg',
        caseStudyUrl:'/case-studies/equifax/',
        caseStudyCategories: [
            "public-relations",
            "research"
        ],
    },
    {
        caseStudyTitle:'For experts, from experts',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2020/08/casestudies-inset-fujitsu.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2020/08/fujitsu-logo.svg',
        caseStudyUrl:'/case-studies/fujitsu-and-microsoft/',
        caseStudyCategories: [
            "advertising"
        ],
    },
    {
        caseStudyTitle:'Maximizing user experience with data',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-hearst.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/hearst-auto-logo-white.svg',
        caseStudyUrl:'/case-studies/black-book/',
        caseStudyCategories: [
            "research"
        ],
    },
    {
        caseStudyTitle:'Generating a buzz to fuel leads',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-mtm.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/03/icon-hpe.svg',
        caseStudyUrl:'/case-studies/hpe/',
        caseStudyCategories: [
            "advertising",
            "interactive",
            "social-media"
        ],
    },
    {
        caseStudyTitle:'Aligning ABM tactics to buyer activity',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2020/08/casestudies-inset-insight.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2020/08/insight-logo.svg',
        caseStudyUrl:'/case-studies/insight-and-microsoft/',
        caseStudyCategories: [
            "advertising"
        ],
    },
    {
        caseStudyTitle:'Integrated success',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/05/portfolio-inset-mdlive.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/05/mdlive-logo-white.svg',
        caseStudyUrl:'/case-studies/mdlive/',
        caseStudyCategories: [
            "advertising",
            "interactive",
            "research"
        ],
    },
    {
        caseStudyTitle:'Refusing to blend in',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-quackskins.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/qauckskins-logo-white.svg',
        caseStudyUrl:'/case-studies/quackskins/',
        caseStudyCategories: [
            "interactive"
        ],
    },
    {
        caseStudyTitle:'Making complex simple',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/05/portfolio-inset-softnas.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/03/icon-softnas.svg',
        caseStudyUrl:'/case-studies/softnas/',
        caseStudyCategories: [
            "interactive",
            "research"
        ],
    },
    {
        caseStudyTitle:'Expanding opportunities',
        caseStudyBGImage:'https://www.meritmile.com/uploads/2019/04/portfolio-inset-swapalease.jpg',
        caseStudyIcon: 'https://www.meritmile.com/uploads/2019/04/case-study-quote.svg',
        caseStudyBottomLogo: 'https://www.meritmile.com/uploads/2019/04/swapalease-logo-white.svg',
        caseStudyUrl:'/case-studies/swapalease/',
        caseStudyCategories: [
            "public-relations",
            "research"
        ],
    }
];

class CaseStudyThumb extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.image+')'
        };
        const divStylesIconTop = {
            backgroundImage: 'url('+this.props.imagetop+')'
        };
        const divStylesIconBottom = {
            backgroundImage: 'url('+this.props.imagebottom+')'
        };
        const categoriesList = this.props.categories;
        let cats = categoriesList.join(' ');
        return (
            <div className={`gallery_product col-lg-4 col-md-6 col-12 filter ${cats}`}>
                <a href={this.props.url} style={divStyles} >
                    <div className="overlay">
                        <div className="icon-top" style={divStylesIconTop} >
                            &nbsp;
                        </div>
                        <div className="title">
                            <div className="wrap"><span dangerouslySetInnerHTML={{ __html: this.props.title}}></span></div>
                        </div>
                        <div className="icon-bottom" style={divStylesIconBottom} >
                            &nbsp;
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

class CaseStudyItemsModule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [
                'advertising', 
                'interactive', 
                'public-relations',
                'research',
                'social-media'
            ]
        };

        this.onCatClick = this.onCatClick.bind(this);
    }

    onCatClick(e) {
        let buttons = document.getElementsByClassName('filter-button');
        // console.log(buttons);
        for (let f = 0; f < buttons.length; f++) {
            buttons[f].style.color = "#000000";
            buttons[f].style.backgroundColor = "#ffffff";
        }
        e.currentTarget.style.color = '#ffffff';
        e.currentTarget.style.backgroundColor = '#000000';
        if(e.currentTarget.getAttribute('data-filter') === 'all') {
            this.setState({
                categories: [
                    'advertising', 
                    'interactive', 
                    'public-relations',
                    'research',
                    'social-media'
                ]});
        } else {
            this.setState({
                categories: [
                    e.currentTarget.getAttribute('data-filter')
                ]
            });
        }

    }
    render() {
        return (
            <div className="container case-studies-items">
                <div className="row">
                    <div className="col">
                        <div align="center" className="filter-btns">
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="all">All</button>
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="advertising">Advertising</button>
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="interactive">Interactive</button>
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="public-relations">Public Relations</button>
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="research">Research</button>
                            <button className="btn btn-default filter-button" onClick={this.onCatClick} data-filter="social-media">Social Media</button>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    {
                        caseStudyItems
                        .filter(e => {
                            for(let i = 0; i < e.caseStudyCategories.length; i++) {
                                if(this.state.categories.includes(e.caseStudyCategories[i])) {
                                    return this.state.categories.includes(e.caseStudyCategories[i]);
                                }
                            }
                            return null;
                        }).map((e,i) => {
                            return (
                                <CaseStudyThumb key={i} title={e.caseStudyTitle} image={e.caseStudyBGImage} imagetop={e.caseStudyIcon} imagebottom={e.caseStudyBottomLogo} url={e.caseStudyUrl} categories={e.caseStudyCategories}
                                />
                            )
                        })
                    }

                </div>  
            </div>
        )
    }

}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />
        <div className="case-study-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.localFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <article className={`${pageContext.slug}`}>
                <div dangerouslySetInnerHTML={{__html: pageContext.content}} />
            </article>
            <CaseStudyItemsModule />   
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>{pageContext.acf.quote_text}</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)